<template>
  <div class="page">
    <div class="infoBox">
      <div class="left">
        <img :src="headImg" alt="" />
      </div>
      <div class="center">
        <div class="name">{{ goodsName }}</div>
        <div class="price"><span>¥</span>{{ goodsPrice }}</div>
        <div class="userName">发布人：{{ nickName }}</div>
      </div>
      <div class="right">
        <img
          v-if="isCollect === 1"
          @click="likeOrNot(false)"
          src="./img/like.png"
          alt=""
        />
        <img v-else @click="likeOrNot(true)" src="./img/no-like.png" alt="" />
      </div>
    </div>
    <div class="msgBox" @click="toPath('disclaimers')">
      <img class="left" src="./img/message.png" alt="" />
      <div class="center">免责说明：“奉化丨家门口”小程序提醒您：在使用…</div>
      <img class="right" src="./img/right-icon.png" alt="" />
    </div>
    <div class="content">
      {{ goodsContent }}
    </div>
    <div class="img">
      <img
        v-for="(item, index) in goodsPhoto"
        :key="index"
        :src="item"
        alt=""
      />
    </div>
    <div class="btn" v-if="msg == '0'" @click="toPath('leaveMsgForSaleMen')">
      给卖家留言
    </div>
    <div class="btn" v-else @click="toPath('goodsLeaveMessage')">留言记录</div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  goodThingsDetailsUrl,
  collectGoodsUrl,
  cancelCollectUrl,
  permissionOfSellerUrl,
} from "./api";
import { handleImg } from "@/utils/utils.js";
export default {
  name: "goodsDetail",
  data() {
    return {
      goodsName: null,
      goodsContent: null,
      goodsPhoto: null,
      goodsPrice: null,
      goodsId: null,
      nickName: null,
      headImg: null,
      isCollect: 0,
      msg: null,
      leaveWordId: null,
    };
  },

  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    if (this.$route.query.goodsId) {
      this.goodsId = this.$route.query.goodsId;
    }
  },
  mounted() {
    this.getDetail();
    this.isSeller();
  },
  methods: {
    toPath(name) {
      this.$router.push({
        name: name,
        query: {
          goodsId: this.goodsId,
          leaveWordId: this.leaveWordId,
        },
      });
    },
    isSeller() {
      let params = {
        userId: this.userId,
        goodsId: this.goodsId,
      };
      this.$axios
        .get(`${permissionOfSellerUrl}`, { params: params })
        .then((res) => {
          if (res.code == 200) {
            this.msg = res.msg;
          }
        });
    },
    likeOrNot(flag) {
      let url = flag ? collectGoodsUrl : cancelCollectUrl;
      let params = {
        goodsId: this.goodsId,
        userId: this.userId,
        tenantId: this.tenantId,
      };
      this.$axios.post(`${url}`, params).then((res) => {
        if (res.code == 200) {
          this.getDetail();
        }
      });
    },
    getDetail() {
      let params = {
        goodsId: this.goodsId,
        userId: this.userId,
      };
      this.$axios
        .get(`${goodThingsDetailsUrl}`, { params: params })
        .then((res) => {
          if (res.code == 200) {
            this.goodsContent = res.data.goodsContent;
            this.goodsPhoto = res.data.goodsPhoto.split(",");
            this.headImg = handleImg(220, 220, this.goodsPhoto[0]);
            this.goodsPrice = res.data.goodsPrice;
            this.nickName = res.data.nickName;
            this.isCollect = res.data.isCollect;
            this.leaveWordId = res.data.leaveWordId;
          }
        });
    },
  },
};
</script>

<style scoped lang='less'>
.page {
  min-height: 100vh;
  box-sizing: border-box;
  padding: 30px 30px 160px 30px;
  .btn {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #ffde6d 0%, #ffd032 100%);
    border-radius: 10px;
    text-align: center;
    line-height: 66px;
    font-size: 30px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    position: fixed;
    bottom: 114px;
    left: 50%;
    transform: translateX(-50%);
  }
  .img {
    width: 100%;
    img {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  .content {
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 40px;
    margin-bottom: 32px;
  }
  .msgBox {
    width: 100%;
    padding: 16px 20px;
    box-sizing: border-box;
    background: rgba(255, 237, 190, 0.21);
    border-radius: 8px;
    line-height: 34px;
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: center;
    margin: 58px 0 42px 0;
    .left {
      width: 30px;
      margin-right: 10px;
    }
    .center {
      flex: 1;
    }
    .right {
      width: 16px;
    }
  }
  .infoBox {
    display: flex;
    .left {
      width: 220px;
      height: 220px;
      border-radius: 14px;
      overflow: hidden;
      margin-right: 36px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .center {
      flex: 1;
      padding-top: 16px;
      box-sizing: border-box;
      .userName {
        margin-top: 48px;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
      }
      .price {
        font-size: 40px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #e02020;
        span {
          font-size: 20px;
        }
      }
      .name {
        font-size: 32px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        margin-bottom: 12px;
      }
    }
    .right {
      margin-left: 36px;
      width: 44px;
      img {
        width: 100%;
      }
    }
  }
}
</style>